import FetchServices from "./FetchServices";
import apiService from "./ApiServices";

export default function MainServices() {
  const { AxiosService } = FetchServices();
  const Services = {
    adminSignin: async function (details) {
      let API = apiService.adminSignin;
      let payload = {
        ...details,
      };
      let type = "POST";
      const res = await AxiosService(API, payload, false, type);
      return res;
    },

    getAllCaseHistory: async function (details) {
      let API = apiService.getAllCaseHistory;
      let payload = {
        ...details,
      };
      let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    getCaseByReference: async function (details) {
      let API = apiService.getCaseByReference + "/" + details.caseReference;
      let payload = {...details};
      let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    createAgent: async function (details) {
      let API = apiService.createAgent;
      let payload = {
        ...details,
      };
      let type = "POST";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    getAllAgents: async function (details) {
      let API = apiService.getAllAgents;
      let payload = {
        ...details,
      };
      let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    getCaseById: async function (details) {
      let API = apiService.getCaseById + "/" + details.id;
      let payload = {};
      let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },
    getCaseByReference: async function (details) {
      let API = apiService.getCaseByReference + "/" + details.caseReference;
      let payload = { ...details };
      let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },
    getServiceByCaseId: async function (details) {
      let API = apiService.getServiceByCaseId + "/" + details.caseId;
      let payload = {};
      let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    deleteAgent: async function (details) {
      let API = apiService.deleteAgent + "/" + details.username;
      let payload = {};
      let type = "DELETE";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    getPaidNotificationStatus: async function () {
      let API = apiService.getPaidNotificationStatus;
      let payload = {};
      let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },
    updatePaidNotificationStatus: async function (details) {
      let API = apiService.updatePaidNotificationStatus + "/" + details.id;
      let payload = details.status;
      let type = "PUT";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    getDreamCaseHistoryByGetCase: async function (details) {
      let API = apiService.getDreamCaseHistoryByGetCase + "/" + details.caseId;
      let payload = {};
      let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    updateService: async function (details) {
      let API = apiService.updateService + "/" + details.id;
      let payload = {
        ...details,
      };
      let type = "PUT";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    cancelService: async function (details) {
      let API = apiService.cancelService;
      let payload = {
        ...details,
      };
      let type = "POST";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    updateCaseVisitStatus: async function (details) {
      let API = apiService.updateCaseVisitStatus + "/" + details.id;
      let payload = details.status;
      let type = "PUT";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },
    updateServiceVisitStatus: async function (details) {
      console.log("details Main: ", details);
      let API = apiService.updateServiceVisitStatus + "/" + details.id;
      let payload = details.status;
      let type = "PUT";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    getAllRates: async function (details) {
      let API = apiService.getAllRates;
      let payload = {
        ...details,
      };
      let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    updateRateById: async function (details, caseId) {
      let API = apiService.updateRateById + "/" + caseId;
      let payload = {
        ...details,
      };
      let type = "PUT";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    addRates: async function (details) {
      let API = apiService.addRates;
      let payload = {
        ...details,
      };
      let type = "POST";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },
  };
  return Services;
}
